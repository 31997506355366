//CSS per LOGIN WP

$siroPaintsRed: #db3b37;
$white: #ffffff;

.login {
	h1 {
		a {
			background-image: none, url(../images/logo-admin.png);
			background-size: 276px;
			width: 276px;
			height: 80px;
		}
	}
}
.wp-core-ui .button-primary {
	background: $siroPaintsRed;
    border-color: $siroPaintsRed;
    -webkit-box-shadow: 0 1px 0 $siroPaintsRed;
    box-shadow: 0 1px 0 $siroPaintsRed;
    color: $white;
    text-decoration: none;
    text-shadow: 0 -1px 1px $siroPaintsRed, 1px 0 1px $siroPaintsRed, 0 1px 1px $siroPaintsRed, -1px 0 1px $siroPaintsRed;
}
.wp-core-ui .button-primary.focus, .wp-core-ui .button-primary.hover, .wp-core-ui .button-primary:focus, .wp-core-ui .button-primary:hover {
	background: $white;
    border-color: $siroPaintsRed;
    color: $siroPaintsRed;
    text-shadow: 0 -1px 1px $white, 1px 0 1px $white, 0 1px 1px $white, -1px 0 1px $white;
     -webkit-box-shadow: 0 1px 0 $white;
    box-shadow: 0 1px 0 $white;
}